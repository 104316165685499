<template>
    <div class="container">
        <div v-if="$slots['default']" ref="slot-default"><slot name="default" /></div>
        <div class="container-menu" ref="container-menu" style="flex:1;">
            <el-menu unique-opened :collapse="collapse" :default-active="sideIndex" :background-color="BackgroundColor" :text-color="TextColor" :active-text-color="TextActiveColor" :style="{'border-right-color': BackgroundColor,'height':sideHeigh}" class="el-menu-vertical">
                <template v-for="(item1, key1) in (sideItems || [])">
                    <router-link  v-if="!item1.items" :key="key1" :to="fullPath(`${item1.path}`||'',`${item1.path||''}`)">
                        <el-menu-item :index="`side-${key1}`" :class="{'menu-item-select':sideIndex == `side-${key1}`}" class="menu-item-defalt">
                            <i :class="item1.icon||'icon-business'" :style="{color:sideIndex == `side-${key1}` ? TextActiveColor : TextColor, fontSize: item1.fontSize || '16px'}" class="icon"/>
                            <span slot="title">{{ item1.title }}</span>
                            <el-badge v-if="item1.badge && item1.badge > 0" :max="99" :value="item1.badge" />
                        </el-menu-item>
                    </router-link>

                    <el-submenu v-else :key="key1" :index="`side-${key1}`">
                        <template slot="title">
                            <i :class="item1.icon || 'icon-business'" :style="{color:sideIndex == `side-${key1}` ? TextActiveColor : TextColor, fontSize: item1.fontSize || '16px'}" class="icon"/>
                            <span slot="title">{{ item1.title }}</span>
                        </template>

                        <span v-for="(item2, key2) in item1.items" :key="`${key1}-${key2}`">
                            <router-link v-if="!item2.items" :key="`${key1}-${key2}`" :to="fullPath(`${item1.path||''}-${item2.path||''}`,`${item2.path||''}`)">
                                <el-menu-item :index="`side-${key1}-${key2}`" :class="{'menu-item-select': sideIndex == `side-${key1}-${key2}`}" class="menu-item-defalt">
                                    <i v-if="item2.icon" :class="item2.icon" :style="{color:sideIndex == `side-${key1}-${key2}` ? TextActiveColor : TextColor, fontSize: item1.fontSize || '16px'}" class="icon"/>
                                    <span slot="title">{{ item2.title }}</span>
                                    <el-badge v-if="item2.badge && item2.badge > 0" :max="99" :value="item2.badge" />
                                </el-menu-item>
                            </router-link>

                            <el-submenu v-else :key="`${key1}-${key2}`" :index="`side-${key1}-${key2}`">
                                <template slot="title">
                                    <i v-if="item2.icon" :class="item2.icon" :style="{color:sideIndex == `side-${key1}-${key2}` ? TextActiveColor : TextColor, fontSize: item1.fontSize || '16px'}" class="icon"/>
                                    <span slot="title">{{ item2.title }}</span>
                                </template>
                                <span v-for="(item3, key3) in item2.items" :key="`${key1}-${key2}-${key3}`">
                                    <router-link :key="`${key1}-${key2}-${key3}`" :to="fullPath(`${item1.path||''}-${item2.path||''}-${item3.path||''}`,`${item3.path||''}`)">
                                        <el-menu-item :index="`side-${key1}-${key2}-${key3}`" :class="{'menu-item-select': sideIndex == `side-${key1}-${key2}-${key3}`}" class="menu-item-defalt">
                                            <i v-if="item3.icon" :class="item3.icon" :style="{color:sideIndex == `side-${key1}-${key2}-${key3}` ? TextActiveColor : TextColor, fontSize: item1.fontSize || '16px'}" class="icon"/>
                                            <span slot="title">{{ item3.title }}</span>
                                            <el-badge v-if="item3.badge && item3.badge > 0" :max="99" :value="item3.badge" />
                                        </el-menu-item>
                                    </router-link>
                                </span>
                            </el-submenu>
                        </span>
                    </el-submenu>
                </template>
            </el-menu>
            <div
                :style="{ 'background-color': BackgroundColor }"
                class="collapse"
            >
                <div class="collapse-container">
                    <img
                        :style="{ right: collapse ? '0' : '3px' }"
                        class="collapse-button"
                        @click="collapse = !collapse"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAMCAYAAACNzvbFAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkU0QUVEMDJBQ0U5MTExRTg5QTI3OEYxRTQyREIzMjY5IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkU0QUVEMDJCQ0U5MTExRTg5QTI3OEYxRTQyREIzMjY5Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RTRBRUQwMjhDRTkxMTFFODlBMjc4RjFFNDJEQjMyNjkiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RTRBRUQwMjlDRTkxMTFFODlBMjc4RjFFNDJEQjMyNjkiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6MJayEAAAAYklEQVR42mL4////kf/kg8NAzICOmRgoA4xYBUEmUxFwAfFUBmzOJxNrAvHl/1Q0MAGIv8ICmoHMiEKOoCh0SSYqRNBPDFkqeT8O3fvUwjpAfB1kKLWTFDcQT2EYMjkKIMAAUDH69/mVXckAAAAASUVORK5CYII=" alt=""
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "sidebar",
    props: {
        TextColor: {type: String,default: "#bebec0"},
        TextActiveColor: {type: String,default: "#fff"},
        BackgroundColor: {type: String,default: "#1D2E54"},
        mainRoute: {type: String,default: "/"},
		sideItems: {
			type: Array,
			default: () => {
				return [];
			}
        }
    },
    data() {
        return {
            sideIndex:'',
            sideIndexCache:{},
            sideHeigh:'400px',
            collapse: false, // 菜单开关、
        };
    },
    mounted() {
        this.resetSideIndexCache();
        this.$nextTick().then(()=>{
            if( this.$refs['slot-default'] && this.$refs['slot-default'].offsetHeight && this.$refs['container-menu'] && this.$refs['container-menu'].offsetHeight ){
                this.sideHeigh = (this.$refs['container-menu'].offsetHeight-this.$refs['slot-default'].offsetHeight)+'px';
            }else if( this.$refs['container-menu'] && this.$refs['container-menu'].offsetHeight ){
                this.sideHeigh = this.$refs['container-menu'].offsetHeight+'px';
            }
        });
    },
    methods:{
        fullPath(path,_path){
            path = path.replace(/(\-){2,}/g, "-")
            if(path.substr(0, 1) == "-"){
                path = path.substr(1);
            }
            if(_path.substr(0, 1) == "/"){
                path = _path;
            }
            return (`${this.mainRoute}/${path}`).replace(/(\/){2,}/g, "/");
        },
        resetSideIndexCache(){
            for( let key1 in this.sideItems ){
                if( this.sideItems[key1].path ){
                    this.sideIndexCache[this.fullPath(`${this.sideItems[key1].path}`,this.sideItems[key1].path)] = `side-${key1}`;
                }

                if( this.sideItems[key1].items ){
                    for( let key2 in this.sideItems[key1].items ){
                        if( this.sideItems[key1].items[key2].path ){
                            this.sideIndexCache[this.fullPath(`${this.sideItems[key1].path||''}-${this.sideItems[key1].items[key2].path||''}`,`${this.sideItems[key1].items[key2].path||''}`)] = `side-${key1}-${key2}`;
                        }

                        if( this.sideItems[key1].items[key2].items ){
                            for( let key3 in this.sideItems[key1].items[key2].items ){
                                if( this.sideItems[key1].items[key2].items[key3].path ){
                                    this.sideIndexCache[this.fullPath(`${this.sideItems[key1].path||''}-${this.sideItems[key1].items[key2].path||''}-${this.sideItems[key1].items[key2].items[key3].path||''}`,`${this.sideItems[key1].items[key2].items[key3].path||''}`)] = `side-${key1}-${key2}-${key3}`;
                                }
                            }
                        }
                    }
                }
            }
            this.sideIndex = this.sideIndexCache[this.$route.path] || '';
        }
    },
    watch: {
        '$route.path'(path){
            this.sideIndex = this.sideIndexCache[this.$route.path] || '';
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
    .el-menu-vertical:not(.el-menu--collapse) {
        width: 200px;
        min-height: 400px;
    }

    .el-menu-vertical {
        height: 100%;
        overflow: auto;
        padding-bottom: 48px;
        .el-submenu.is-active {
            .el-submenu__title {
                .icon {
                    color: white;
                }
                span {
                    color: white;
                }
            }
        }
        .icon {
            margin-right: 8px;
        }
        .menu-item-defalt {
            border-left: 2px solid transparent;
            height: 46px;
            line-height: 46px;
        }

        .menu-item-select {
            border-left: 2px solid #3e84e9;
            background-color: #454e57 !important;
        }

    }
}


.collapse {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 48px;

    .collapse-container {
        position: relative;
        height: 48px;
        .collapse-button {
            position: absolute;
            top: 0;
            padding: 18px 20px;
        }
    }
}

// 消息数
.el-badge {
    position: absolute;
    right: 15px;
    top: 5px;
    >>> .el-badge__content {
        border-width: 0;
    }
}
</style>
