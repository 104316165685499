<template>
	<el-container style="height:100%;width:100%">
        <el-header class="nav-container">
            <navbar :navItems="navItems" :main-route="mainRoute" @reload="reload">
                <slot name="header" />
            </navbar>
        </el-header>
        <el-container>
            <el-aside width="auto" class="aside-container">
                <sidebar :side-items="sideItems" :main-route="mainRoute">
                    <slot name="default" />
                </sidebar>
            </el-aside>
            <el-main>
                <router-view v-if="isAlive" />
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import navbar from './navbar';
import sidebar from './sidebar';
export default {
    name: 'layout',
    components: {navbar,sidebar},
	props: {
        mainRoute: {type: String,default: "/"},
		navItems: {
			type: Array,
			default: () => {
				return [];
			}
        },
        sideItems:{
            type: Array,
			default: () => {
				return [];
			}
        }
    },
    data () {
        return {
            isAlive:true,
        }
    },
    methods: {
        reload(){
            this.isAlive = false;
            this.$nextTick(()=>{
                this.isAlive = true;
            })
        }
    }
};
</script>
<style lang="scss" scoped>
#app {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.nav-container {
    padding: 0;
    z-index: 100;
    min-width: 1200px;
}

.aside-container {
    position: relative;
    background-color: #2d3037;
    box-sizing: border-box;
    border-right: solid 1px #e6e6e6;
}

.el-container {
    overflow: hidden;
}
</style>