<template>
	<div class="navbar">
		<div class="logo" @click="$emit('reload')">
			<!-- <img :src="$loadImg('logo.png',true)"> -->
			{{$config.name || '控制台'}}
		</div>
		<div class="nav-items">
			<router-link v-for="(item, index) in navItems" :key="index" :to="item.path"
				:style="{ 'color' : item.path == mainRoute ? '#3E84E9' : '#333333' }" class="nav-item">
				<i v-if="item.icon" :style="{ 'color': item.path == mainRoute ? '#3E84E9' : '#333333'}"
					:class="item.icon" class="icon" style="margin-right: 10px;" />
				<div class="nav-item-title">{{ item.title }}</div>
			</router-link>
		</div>
		<div v-if="$slots['default']" ref="slot-default">
			<slot name="default" />
		</div>
		<div style="margin-right: 15px;">
			<el-button type="success" @click="LoginOut()">退出登录</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'navbar',
		props: {
			mainRoute: {
				type: String,
				default: "/"
			},
			navItems: {
				type: Array,
				default: () => {
					return [];
				}
			},
		},
		data() {
		    return {
		        info:{}
		    };
		},
		mounted() {
		},
		methods: {
			LoginOut() {
				this.$confirm('您正在退出登录', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$vuex('AdminInfo',{},true);
					this.$vuex('UserCheck',{},true);
					this.$router.replace("/");
					this.$message({
						type: 'success',
						message: '退出成功!'
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			}
		}
	};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
	.navbar {
		height: 60px;
		min-height: 60px;
		background-color: white;
		display: flex;
		align-items: center;
		position: relative;
		.logo {
			width: 200px;
			height: 60px;
			line-height: 42px;
			font-size: 20px;
			letter-spacing: 10px;
			flex-shrink: 0;
			margin-right: 15px;
			padding: 10px 0 10px 10px;
			display: block;
			object-fit: cover;
			text-align: center;
			background-color: #1D2E54;
			color: #ffffff;
		}

		.nav-items {
			flex: 1;
			display: flex;
			min-width: 500px;
			height: 100%;
			line-height: 60px;
			overflow-x: auto;
			font-size: 15px;
			font-weight: 700;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			box-sizing: border-box;
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-webkit-flex-direction: row;
			-ms-flex-direction: row;
			flex-wrap: wrap;
			flex-direction: row;

			.nav-item {
				padding: 0 30px;
				display: flex;
				align-items: center;
				cursor: pointer;

				.nav-item-img {
					width: 24px;
					height: 24px;
					display: block;
					margin-right: 5px;
				}
			}
		}
	}
</style>
